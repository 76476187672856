import {IconButton, Tooltip} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import {
    cleanRows,
    createRows,
    filterRows,
    getRowsByWeek,
    TimetableActivityParent
} from "../../middleware/timeTableMiddleware";
import {TimeTable, useGetTimetable} from "../../middleware/activitiesMiddleware";
import {TimeTablePage} from "../pdf/TimeTablePage";
import {usePDF} from "@react-pdf/renderer";
import {useTranslation} from "react-i18next";
import {DisabledDownloadButton} from "../selectionBox/buttons/DisabledDownloadButton";
import {useGetStaffTimeTable} from "../../middleware/staffMiddleware";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../app/hooks";
import {useGetRoomTimeTable} from "../../middleware/roomMiddleware";

type PdfDownloadButtonProps = {
    semester: string,
    week: number,
    classId?: string | undefined,
    roomId?: string | undefined,
    staffId?: string | undefined,
};

type PdfDownloadButtonInnerProps = {
    fileName: string,
    semester: string,
    week: number,
    title: string,
    isClass: boolean,
    isRoom: boolean,
    isStaff: boolean,
    data: TimeTable | undefined | null,
}

export const PdfDownloadButton = (props: PdfDownloadButtonProps) => {
    const [titleText, setTitleText] = useState<string>("");
    const isClass = props.classId !== undefined && props.classId !== "";
    const isRoom = props.roomId !== undefined && props.roomId !== "";
    const isStaff = props.staffId !== undefined && props.staffId !== "";

    const timetable = useGetTimetable()
    const roomTimeTable = useGetRoomTimeTable();
    const staffTimeTable = useGetStaffTimeTable();

    const className = useAppSelector(state => state.classesState.className);
    const roomName = useAppSelector(state => state.roomState.roomName);
    const lecturerName = useAppSelector(state => state.lecturerState.lecturerName);
    const [fileName, setFileName] = useState<string>("");
    const {t} = useTranslation();

    useEffect(() => {
        if (isClass) {
            if (props.classId?.includes("@")) {
                setTitleText("Stundenplan für Seminargruppe/Matrikel: " + props.classId?.split("@")[1]);
            } else {
                setTitleText("Stundenplan für Seminargruppe/Matrikel: " + className);
            }
            setFileName(className.replace(" ", "_") + ".pdf");
        }
        if (isRoom) {
            setTitleText("Stundenplan für Raum: " + roomName);
            setFileName(roomName.replace(" ", "_") + ".pdf");
        }
        if (isStaff) {
            setTitleText("Stundenplan für Lehrperson: " + lecturerName);
            setFileName(lecturerName.replace(" ", "_") + ".pdf");
        }
    }, [className, isClass, isRoom, isStaff, props.classId, props.roomId, props.staffId, roomName, lecturerName])

    if (
        (timetable.isLoading || timetable.isError) &&
        (roomTimeTable.isLoading || roomTimeTable.isError) &&
        (staffTimeTable.isLoading || staffTimeTable.isError)
    ) {
        return (
            <DisabledDownloadButton
                tooltip={t("selectionBoxes.downloads.ics.title")}
                icon={PrintIcon}
            />
        )
    }

    if (isClass) {
        return (
            <PdfDownloadButtonInner
                fileName={fileName}
                semester={props.semester}
                week={props.week}
                title={titleText}
                isClass={isClass}
                isRoom={isRoom}
                isStaff={isStaff}
                data={timetable.data}
            />
        )
    }

    if (isRoom) {
        return (
            <PdfDownloadButtonInner
                fileName={fileName}
                semester={props.semester}
                week={props.week}
                title={titleText}
                isClass={isClass}
                isRoom={isRoom}
                isStaff={isStaff}
                data={roomTimeTable.data}
            />
        )
    }

    if (isStaff) {
        return (
            <PdfDownloadButtonInner
                fileName={fileName}
                semester={props.semester}
                week={props.week}
                title={titleText}
                isClass={isClass}
                isRoom={isRoom}
                isStaff={isStaff}
                data={staffTimeTable.data}
            />
        )
    }

    return (
        <DisabledDownloadButton
            tooltip={t("selectionBoxes.downloads.ics.title")}
            icon={PrintIcon}
        />
    )
}

const PdfDownloadButtonInner = (props: PdfDownloadButtonInnerProps) => {
    const {t} = useTranslation();
    const isKolleg = useAppSelector(state => state.authoritiesCookieState.department) === "Kolleg";
    const currentWeek: number = useAppSelector(state => state.weekState.week);
    const showOnlyExams = useAppSelector(state => state.showOnlyExamState.showOnlyExam);
    const [cleanedRows, setCleanedRows] = useState<TimetableActivityParent[]>([]);
    const rows = getPlanByWeek(currentWeek, cleanedRows);

    const pdf = (rows: TimetableActivityParent[]) => (
        <TimeTablePage
            rows={rows}
            semester={props.semester}
            week={props.week}
            title={props.title}
            isClass={props.isClass}
            isRoom={props.isRoom}
            isStaff={props.isStaff}
            isKolleg={isKolleg}
        />
    )

    const [instance, updateInstance] = usePDF({document: pdf(rows)})

    useEffect(() => {
        setCleanedRows(cleanRows(filterRows(createRows(props.data), showOnlyExams)));
    }, [currentWeek, showOnlyExams, props.data]);

    useEffect(() => {
        updateInstance(pdf(rows));
    }, [cleanedRows]);

    if (instance.loading || instance.error) {
        return (
            <IconButton disabled>
                <PrintIcon/>
            </IconButton>
        )
    }

    return (
        <Tooltip title={t("selectionBoxes.downloads.pdf")}>
            <IconButton
                href={instance.url!}
                download={props.fileName}
            >
                <PrintIcon/>
            </IconButton>
        </Tooltip>
    )
}

const getPlanByWeek = (week: number, rows: TimetableActivityParent[]): TimetableActivityParent[] => {
    if (week === 0) {
        return rows;
    } else {
        return getRowsByWeek(week, rows);
    }
}