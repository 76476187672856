import {experimental_extendTheme as extendTheme} from '@mui/material/styles';
import {blue, green, grey, indigo, red} from "@mui/material/colors";

export const HSZG_MAIN_COLOR: string = "#5FA816";
export const HSZG_MAIN_ACTIVE_COLOR: string = "#7FC557"
export const HSZG_SECONDARY_COLOR: string = "#EDEDED";
export const HSZG_TEXT_MAIN_COLOR: string = "#ffffff";
export const HSZG_MAIN_ACTIVE_DARK_COLOR: string = "#474747";
export const HSZG_LIGHT_GRAY: string = "#EDEDED";
export const HSZG_HOVER_GRAY: string = "#CCCCCC";

export const defaultColor: string = grey[700];

export const activityColors = {
    "prüfung": red[500],
    "testat": red[600],
    "vorlesung": blue[500],
    "einführung": blue[600],
    "seminar": green[500],
    "praktikum": green[600],
    "übung": green[700],
    "tutorium": green[800],
    "exkursion": indigo[500],
}

export const mainTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {},
        },
        dark: {
            palette: {},
        },
    },
});

export const getColorForActivity = (activity: string): string => {
    for (let key in activityColors) {
        if (activity.toLowerCase().includes(key)) {
            return activityColors[key];
        }
    }
    return defaultColor;
}