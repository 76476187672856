import {Document, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {TimetableActivityParent} from "../../middleware/timeTableMiddleware";
import image from './images/HZG_Logo_RGB.png';
import {getActivitiesForDay} from "../../util/getActivitiesForDay";
import {ClassPdfTable} from "./tables/class/ClassPdfTable";
import {RoomPdfTable} from "./tables/room/RoomPdfTable";
import {StaffPdfTable} from "./tables/staff/StaffPdfTable";

const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        flexDirection: "column",
        backgroundColor: "#ffffff",
    },
    header: {
        width: "100%",
        height: "auto",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 10,
    },
    image: {
        width: "200px",
        height: "57px",
    },
    watermark: {
        fontSize: 14,
        textAlign: "right",
    },
    informations: {
        width: "100%",
        height: "auto",
        marginBottom: 20,
    },
    title: {
        marginBottom: 12,
        marginTop: 12,
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "justify",
    },
    infotext: {
        fontSize: 11,
        textAlign: "justify",
    },
    tables: {},
    tableTitle: {
        fontSize: 15,
        textAlign: "justify",
        paddingTop: "15px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        marginBottom: "2px",
    },
    tablesHeader: {
        textAlign: "center",
        fontSize: 12,
        padding: "2.5px",
    },
    tablesCell: {
        fontSize: 10,
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },
    tableActivityCell: {
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontSize: 10,
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        fontSize: 10,
        textAlign: 'center'
    },
    tableCellWeeks: {
        textAlign: "center",
        fontSize: 8,
        padding: "2.5px",
        textOverflow: "ellipsis"
    },
});

type TimeTablePagePops = {
    rows: TimetableActivityParent[],
    title: string,
    semester: string,
    week: number,
    isClass: boolean,
    isRoom: boolean,
    isStaff: boolean,
    isKolleg: boolean,
};

type DayTableProps = {
    rows: TimetableActivityParent[],
    day: string,
    semester: string,
    week: number,
    isClass: boolean,
    isRoom: boolean,
    isStaff: boolean,
}

export const TimeTablePage = (props: TimeTablePagePops) => {
    const currentDate = new Date();
    const mondayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Montag");
    const tuesdayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Dienstag");
    const wednesdayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Mittwoch");
    const thursdayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Donnerstag");
    const fridayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Freitag");
    const saturdayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Samstag");
    const sundayRows: TimetableActivityParent[] = getActivitiesForDay(props.rows, "Sonntag");

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image style={styles.image} src={image} source={image}/>
                    <View>
                        <Text style={styles.watermark}>
                            Online-Stundenplan
                        </Text>
                        <Text style={styles.watermark}>
                            &copy;Hochschule Zittau/Görlitz
                        </Text>
                    </View>
                </View>
                <View style={styles.informations}>
                    <Text style={styles.title}>
                        {props.title}
                    </Text>
                    <Text style={styles.infotext}>
                        {props.isKolleg && (
                            "Kolleg"
                        )}
                    </Text>
                    {props.week !== 0 && (
                        <Text style={styles.infotext}>
                            Kalenderwoche: {props.week}
                        </Text>
                    )}
                    <Text style={styles.infotext}>
                        Stand: {currentDate.toLocaleDateString()}
                    </Text>
                </View>
                <View style={styles.tables}>
                    {
                        mondayRows.length > 0 ? (
                            <DayTable
                                rows={mondayRows}
                                day={"Montag"}
                                semester={props.semester}
                                week={props.week}
                                isClass={props.isClass}
                                isRoom={props.isRoom}
                                isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                    {
                        tuesdayRows.length > 0 ? (
                            <DayTable
                                rows={tuesdayRows}
                                day={"Dienstag"}
                                semester={props.semester}
                                week={props.week}
                                isClass={props.isClass}
                                isRoom={props.isRoom}
                                isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                    {
                        wednesdayRows.length > 0 ? (
                            <DayTable
                                rows={wednesdayRows}
                                day={"Mittwoch"}
                                semester={props.semester}
                                week={props.week}
                                isClass={props.isClass}
                                isRoom={props.isRoom}
                                isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                    {
                        thursdayRows.length > 0 ? (
                            <DayTable rows={thursdayRows}
                                      day={"Donnerstag"}
                                      semester={props.semester}
                                      week={props.week}
                                      isClass={props.isClass}
                                      isRoom={props.isRoom}
                                      isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                    {
                        fridayRows.length > 0 ? (
                            <DayTable
                                rows={fridayRows}
                                day={"Freitag"}
                                semester={props.semester}
                                week={props.week}
                                isClass={props.isClass}
                                isRoom={props.isRoom}
                                isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                    {
                        saturdayRows.length > 0 ? (
                            <DayTable
                                rows={saturdayRows}
                                day={"Samstag"}
                                semester={props.semester}
                                week={props.week}
                                isClass={props.isClass}
                                isRoom={props.isRoom}
                                isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                    {
                        sundayRows.length > 0 ? (
                            <DayTable
                                rows={sundayRows}
                                day={"Sonntag"}
                                semester={props.semester}
                                week={props.week}
                                isClass={props.isClass}
                                isRoom={props.isRoom}
                                isStaff={props.isStaff}
                            />
                        ) : (
                            <></>
                        )
                    }
                </View>
                <Text
                    style={styles.pageNumbers}
                    render={({pageNumber, totalPages}) => (
                        `${pageNumber} / ${totalPages}`
                    )}
                    fixed
                />
            </Page>
        </Document>
    )
}

const DayTable = (props: DayTableProps) => {


    return (
        <View wrap={false}>
            <Text style={styles.tableTitle}>
                {props.day}
            </Text>
            {props.isClass && (
                <ClassPdfTable rows={props.rows}/>
            )}
            {props.isRoom && (
                <RoomPdfTable rows={props.rows}/>
            )}
            {props.isStaff && (
                <StaffPdfTable rows={props.rows}/>
            )}
        </View>
    )
}